<template>
  <v-app>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Order Management & Statistical
      </h1>
    </div>
    <b-card no-body>
      <b-tabs pills card v-model="currentStep">
        <b-tab
          title="Quản lý Order"
          active
          title-link-class="btn btn-light-info"
        >
          <ListOrder></ListOrder>
        </b-tab>
        <b-tab title="Thống kê" title-link-class="btn btn-light-info">
          <StatisticalManagement></StatisticalManagement>
        </b-tab>
      </b-tabs>
    </b-card>
  </v-app>
</template>

<script>
export default {
  name: "TabOrderStatistical",
  components: {
    ListOrder: () => import("./ListOrder.vue"),
    StatisticalManagement: () => import("./StatisticalManagement.vue"),
  },
  data() {
    return {
      currentStep: 0,
    };
  },
};
</script>

<style></style>
